import React from "react"
import { Link } from "gatsby"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"

// import Image from "../components/image"

const SuggestPage = ({data}) => (
  <Layout pageTitle="Suggest a change" enableShare={true} >
    <SEO title="Suggest a change/addition" description="Make a suggestion for a new topic/platform/feature/community on the Hive Index" />

    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-200" />
      </div>
      <div className="relative flex items-center justify-between">
        <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
          <div>Contribute to the Hive Index</div>
        </h2>
      </div>
    </div>
    <div className="mt-2 text-gray-500">
      <p className="mb-2">
        The Hive Index is the destination of thousands of visitors a month who are looking for a community to join.
        We'd like to make this resource as valuable as possible, and could use your help.
        If you have an idea of something that could be improved, please let us know.
      </p>
    </div>

    <div className="relative mt-6">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-200" />
      </div>
      <div className="relative flex items-center justify-between">
        <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
          <div>Types of suggestions we'd like tips on</div>
        </h2>
      </div>
    </div>
    <div className="mt-2 text-gray-500">
      <ul className="list-disc pl-6">
        <li>Some information about a particular community is incorrect or outdated.</li>
        <li>New <Link to="/topics/" className="font-semibold underline">topics</Link> you'd like for us to curate.</li>
        <li>New <Link to="/platforms/" className="font-semibold underline">community platforms</Link> we should explore.</li>
        <li>New <Link to="/features/" className="font-semibold underline">common community features</Link> that we should include on each community.</li>
      </ul>
    </div>

    <div className="relative mt-6">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-200" />
      </div>
      <div className="relative flex items-center justify-between">
        <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
          <div>New communities</div>
        </h2>
      </div>
    </div>
    <div className="mt-2 text-gray-500">
      If you would like to suggest new communities to add, and you are the owner of the community, please checkout the <Link to="/submit/" className="font-semibold underline">submission requirements</Link>. If you are not the owner of the community but just know of one that we should add, email us the url of the community.
    </div>

    <div className="relative mt-8">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-200" />
      </div>
      <div className="relative flex items-center justify-between">
        <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
          <div>How to submit suggestions</div>
        </h2>
      </div>
    </div>
    <div className="mt-2 text-gray-500">
      To submit a change suggestions, please email <a href="mailto:contact@thehiveindex.com?subject=Suggestion" className="font-semibold underline text-rose-600">
        contact@thehiveindex.com</a> with the following information:
      <br/>
      <ul className="list-disc pl-6">
        <li>thehiveindex.com URL that could use the change</li>
        <li>A short description of the change/addition</li>
      </ul>
    </div>


  </Layout>
)


export default SuggestPage
